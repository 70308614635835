<template>
  <div v-if="data[0]" class="tech-support-card-id item">
    <UiLoader v-model="loader" />
    <div class="item__header" v-if="!loader">
      <div class="item__header-left">
        <p class="item-id">{{ $t('request', {data: data[0].id || null}) }}</p>
        <div class="status">
          <span :style="{ backgroundColor: getTechSupportStatus(data[0].status).color}"></span>
          <p class="status__text">{{ $t(getTechSupportStatus(data[0].status).text) }}</p>
        </div>
      </div>
      <div class="item__header-right">
        <div class="item__header-right-avatar">
          <p>{{ $t('request-author') }}</p>
          <router-link :to="`/user/${data[0].createdBy?.id}`" tag="p" style="cursor: pointer" v-if="data[0].createdBy || data[0].createdBy?.login">
            <img alt="" :src="getUserAvatar(data[0].createdBy?.avatarSrc.fileSrc)" />
            {{ data[0].createdBy?.login || null }}
          </router-link>
        </div>
        <div class="item__header-right-email">
          <img src="@/assets/svg/icon-mail.svg" alt="">
          <p>{{ data[0].email || null }}</p>
        </div>
      </div>
    </div>
    <div class="item__line" v-if="!loader"></div>
    <div class="item__body" v-if="!loader">
      <div class="item__body-data">
        <p class="item__body-data-title">{{ $t('date-created') }}</p>
        <div class="item__body-data-time">
          <p>{{ getFullDate(data[0].createdAt).slice(0, 10) || null }}</p>
          <span></span>
          <p>{{ getFullDate(data[0].createdAt).slice(12, 17) || null }}</p>
        </div>
      </div>
      <div class="item__body-subject">
        <p>{{ $t('request-subject') }}</p>
        <p class="subject-text">{{ data[0].subject }}</p>
        <div class="empty" v-if="data[0].subject === ''">
          {{ $t('empty') }}
        </div>
      </div>
      <div class="item__body-description">
        <p>{{ $t('description') }}</p>
        <p class="desc-text">{{ data[0].description }}</p>
        <div class="empty" v-if="data[0].description === ''">
          {{ $t('empty') }}
        </div>
      </div>
      <div class="item__body-files">
        <p>{{ $t('attached-documents') }}</p>
        <div>
          <a
              v-for="(item, key) in data[0].files"
              :key="key"
              :href="`https://xn--b1add.xn--e1aabhcpqfgk.xn--j1agca3a5c.xn--p1ai/public/upload/document/${item}`"
              target="_blank"
              style="display: flex; align-items: center; gap: 10px;"
              class="file"
          >
            <img
                class="cols__item-upload"
                src="@/assets/svg/icon-docs-grey.svg"
                alt=""
            />
            <div class="file-info">
              <p>{{ item }}</p>
              <span>{{ getFullDate(data[0].createdAt) || null }}</span>
            </div>
          </a>
          <div class="empty" v-if="data[0].files.length === 0">
            {{ $t('empty') }}
          </div>
        </div>
      </div>
      <ui-checkbox v-if="anonymous" v-model="checkboxValue">
        <p>{{ $t('letter-sent-to-email') }}</p>
      </ui-checkbox>
      <div class="action" v-if="!isResolve && data[0].status !== 'Resolved'">
        <ui-button v-if="data[0].status === 'InWork'" @click="isResolve=true">{{ $t('close-request') }}</ui-button>
        <ui-button v-else color="error" @click="getToWork">{{ $t('transfer-to-work-2') }}</ui-button>
      </div>
      <div class="item__body-resolve" v-if="isResolve">
        <ui-textarea
            v-model="resolveDescription"
            height="80"
            radius="10"
            :label="$t('solution')"
            required-field
        />
        <div class="item__body-resolve-action">
          <ui-button @click="resolveRequest" :disabled="!resolveDescription">{{ $t('send') }}</ui-button>
          <ui-button color="white" class="cancel-button" @click="isResolve=false">{{ $t('cancel-2') }}</ui-button>
        </div>
      </div>
      <div class="item__body-closed" v-if="data[0].status === 'Resolved'">
        <p class="item__body-closed-title">{{ $t('solution') }}</p>
        <p class="item__body-closed-subtitle">{{ $t('solution-description') }}</p>
        <p class="item__body-closed-description">{{ data[0].response }}</p>
        <p class="item__body-closed-date">{{ getFullDate(data[0].createdAt) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {getFullDate, getTechSupportStatus} from '@/utils/general'
import { getUserAvatar } from "@/utils/user";
import {mapActions} from "vuex";
import UiTextarea from "@/components/ui/UiTextarea.vue";
import UiLoader from "@/components/ui/UiLoader.vue";
export default {
  name: "TechSupportCardId",
  components: {
    UiLoader,
    UiTextarea,
    UiButton: () => import("@/components/ui/UiButton"),
    UiCheckbox: () => import("@/components/ui/UiCheckbox"),
  },

  data() {
    return {
      anonymous: false,
      checkboxValue: true,
      data: {},
      getFullDate,
      getUserAvatar,
      resolveDescription: '',
      isResolve: false,
      loader: true
    }
  },

   mounted() {
    this.sendRequests({skip: 0, take: 500}).then((res) => {
      let { id } = this.$route.params
      this.data = res.data.filter((elem) => {
        return +elem.id === +id
      })
      this.loader = false
    })
  },
  computed: {
    username() {
      return this.anonymous ? this.$t('anonymous') :
          this.data[0].createdBy.lastName ?
              this.data[0].createdBy.lastName + ' ' + this.data[0].createdBy.firstName : this.$t('empty')
    }
  },

  methods: {
    getTechSupportStatus,
    ...mapActions(['sendRequests', 'requestGetWork', 'requestResolve']),
    getToWork() {
      this.loader = true
      this.requestGetWork({id: this.data[0].id}).then(() => {
        this.sendRequests({skip: 0, take: 500}).then((res) => {
          let { id } = this.$route.params
          this.data = res.data.filter((elem) => {
            return +elem.id === +id
          })
          this.loader = false
        })
      })
    },
    resolveRequest() {
      this.loader = true
      this.requestResolve({id: this.data[0].id, response: this.resolveDescription}).then(() => {
        this.sendRequests({skip: 0, take: 500}).then((res) => {
          let { id } = this.$route.params
          this.data = res.data.filter((elem) => {
            return +elem.id === +id
          })
          this.loader = false
          this.isResolve = false
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  width: 100%;
  max-width: 1000px;
  padding: 40px 25px 40px 30px;
  background: #FFFFFF;
  border-radius: 20px;

  @media (max-width: 768px) {
    padding: 30px 12px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;

    &-left {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .item-id {
        font-size: 16px;
        line-height: 15px;
        color: #343432;
      }

      .status {
        display: flex;
        gap: 5px;

        span {
          width: 10px;
          height: 10px;
          box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
          border-radius: 200px;
        }

        &__text {
          font-size: 12px;
          line-height: 11px;
          color: #343432;
        }
      }
    }

    &-right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 10px;

      &-email {
        display: flex;
        align-items: center;
        gap: 5px;
        font-weight: 400;
        font-size: 14px;
        line-height: 120.2%;
        color: #1B1A1F;
      }

      &-avatar {
        display: flex;
        align-items: center;
        gap: 10px;

        p {
          display: flex;
          align-items: center;
          gap: 5px;
          font-size: 16px;
          line-height: 15px;
          color: #343432;

          img {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            border: 1px solid #CE2121;
          }
        }
      }
    }
  }

  &__line {
    width: 100%;
    border-bottom: 1px solid #F5F5F5;
    margin-bottom: 30px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (max-width: 992px) {
      gap: 0;
      flex-direction: column;
    }

    &-data {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &-title {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #1B1A1F;
      }

      &-time {
        display: flex;
        align-items: center;
        gap: 10px;

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 120.2%;
          color: #1B1A1F;
          font-family: 'Gotham Pro regular', sans-serif;
        }

        span {
          width: 4px;
          height: 4px;
          background: #1B1A1F;
          border-radius: 50%;
        }
      }
    }

    &-subject {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .subject-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 120.2%;
        color: #1B1A1F;
        font-family: 'Gotham Pro regular', sans-serif;
      }
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 120.2%;
        color: #1B1A1F;
      }
    }

    &-description {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .desc-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 120.2%;
        color: #1B1A1F;
        font-family: 'Gotham Pro regular', sans-serif;
      }

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 120.2%;
        color: #1B1A1F;
      }
    }

    &-files {
      display: flex;
      flex-direction: column;
      gap: 10px;

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 120.2%;
        color: #1B1A1F;
      }

      div {
        display: flex;
        gap: 10px;
        overflow-x: scroll;
        padding: 10px 0;

        &::-webkit-scrollbar {
          width: 20px;
          height: 4px;
        }

        &::-webkit-scrollbar-thumb {
          background: #9A9A9A;
          border-radius: 10px;
        }
      }
    }

    .action {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    &-resolve {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 20px 30px;
      border: 2px dashed #F5F5F5;
      border-radius: 10px;
      max-width: 600px;

      @media (max-width: 500px) {
        padding: 15px;
      }

      &-action {
        display: flex;
        align-items: center;
        gap: 10px;

        @media (max-width: 500px) {
          flex-direction: column;
        }
      }
    }

    &-closed {
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding: 20px 30px;
      border: 2px dashed #F5F5F5;
      border-radius: 10px;
      max-width: 600px;

      @media (max-width: 500px) {
        padding: 15px;
      }

      &-title {
        font-family: "Gotham Pro Medium";
        font-weight: 400;
        font-size: 14px;
        color: #1B1A1F;
      }

      &-subtitle {
        font-family: "Gotham Pro regular";
        font-weight: 400;
        font-size: 12px;
        color: #9A9A9A;
      }

      &-description {
        font-family: "Gotham Pro regular";
        font-weight: 400;
        font-size: 14px;
        color: #1B1A1F;
      }

      &-date {
        font-family: "Gotham Pro regular";
        font-weight: 400;
        font-size: 12px;
        color: #9A9A9A;
      }
    }
  }
}
::v-deep .button {
  max-width: 517px;
  width: 100%;
}
.empty {
  padding: 10px 0 !important;
  text-align: start !important;
}
.file {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 114px;
  max-width: 116px;
  gap: 10px;

  &-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;

    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      color: #1B1A1F;
      word-break: break-all;
    }

    span {
      font-weight: 400;
      font-size: 10px;
      line-height: 112.7%;
      color: #BFBDBD;
      width: 58px;
      text-align: center;
    }
  }
}
</style>
